import React from 'react';
import './Timer.css';

const Timer = ({ blink, minutes, seconds, onClick, warn }) => {
  return (
    <div className={`Timer ${blink && 'Timer-blink'} ${warn && 'Timer-warn'}`}>
      <div className="Timer-mins" onClick={onClick}>
        {warn && '+'}
        {minutes.toString().padStart(2, '0')}
      </div>
      <div className="Timer-middle">:</div>
      <div className="Timer-secs" onClick={onClick}>
        {seconds.toString().padStart(2, '0')}
      </div>
    </div>
  );
};

export default Timer;
