import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import { checkConfig } from './utils/config';
import * as serviceWorker from './serviceWorker';

// checkConfig();

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();
