// TODO: config is in URL
export const checkConfig = () => {
  // const { config } = window;
  // if (!config) {
  //   alert('CHYBA: Nebolo mozne najst nastavenia hry !!!');
  // }
  // if (typeof getGameTime() !== 'number') {
  //   alert(
  //     'CHYBA: Nebolo mozne najst nastavenie hry - dlzka hry (Napr. `cas = 60`) !!!'
  //   );
  // }
};

export const getGameTime = () => {
  //window.config.cas;
  const time = window.location.hash.match(/#cas=([\d.]+)/);
  if (time) {
    return time[1];
  }
  return 70;
};
