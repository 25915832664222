import { useEffect } from 'react';
import { playGong } from './sound';

const handleKeyDown = (event) => {
  switch (event.keyCode) {
    case 192: // Backslash `, most left top key
      console.log('[Gong] Going to play sound');
      playGong();
      break;
    default:
      break;
  }
};

/**
 * Gong is active listener which will trigger Gong sound when special keybaord combination is pushed
 */
const Gong = () => {
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  // Dont render anything
  return null;
};

export default Gong;
