import React, { useEffect, useState } from 'react';
import './Game.css';
import Timer from '../Timer';
import Gong from '../Gong';
import { getGameTime } from '../../utils/config';
import { useInterval } from '../hooks';

const gameTimeSec = getGameTime() * 60;

const Game = () => {
  const [state, setState] = useState({
    paused: true,
    startTime: new Date(),
    currentTime: new Date(),
  });

  useInterval(() => {
    if (state.paused) {
      return;
    }
    setState((prevState) => ({ ...prevState, currentTime: new Date() }));
  }, 100);

  useEffect(() => {
    // Start game after slight delay
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        paused: false,
        startTime: new Date(),
        currentTime: new Date(),
      }));
    }, 4000);
  }, []);

  const handleTimerClick = () => {
    setState((prevState) => ({
      ...prevState,
      paused: !prevState.paused,
    }));
  };

  const { startTime, currentTime } = state;
  const spentSeconds = (currentTime - startTime) / 1000;
  let remainingSeconds = gameTimeSec - spentSeconds;
  const timeEnded = remainingSeconds < 0;
  remainingSeconds *= timeEnded ? -1 : 1;

  const mins = Math.floor(remainingSeconds / 60);
  const secs = Math.floor(remainingSeconds - mins * 60);
  return (
    <div className="Game">
      {/* <div style={{ color: 'white' }}>{spentTime}</div> */}
      <Gong />
      <div className="Game-text" contentEditable="true" spellCheck={false} />
      <div className="Game-timer">
        <Timer
          minutes={mins}
          seconds={secs}
          onClick={handleTimerClick}
          warn={timeEnded}
        />
      </div>
    </div>
  );
};

export default Game;
