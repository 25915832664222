import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Game from './components/Game';
// import IntroVideo from './components/IntroVideo';

const App = () => {
  return (
    <div className="App">
      <Router>
        <div>
          <Switch>
            {/* <Route exact from="/" component={IntroVideo} /> */}
            <Route exact from="/" component={Game} />
            <Route exact path="/game" component={Game} />
          </Switch>
        </div>
      </Router>
    </div>
  );
};

export default App;
